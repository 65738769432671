import { Injectable } from '@angular/core';
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, switchMap } from "rxjs/operators";
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly DATA_URL = 'https://globexpace.com/admin';
  private readonly API_VERSION: string = 'v1';
  private config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` }
  };
  private configform = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
    }
  };

  constructor() {}

  public postPopular() {
    return axios
      .get(
        `${this.DATA_URL}/module/post/${this.API_VERSION}/post/popular`
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public addPost(title: string, content: string, mediaUrl: string, type: string) {
    let body = {};

    if (mediaUrl.length > 0)
      body = {
        title: title,
        content: content,
        slug: this.getSlug(title, " ", "-"),
        metaTitle: title,
        metaDescription: type,
        summary: title,
        published: true,
        tags: ["1"],
        category: "1",
        thumbnail: mediaUrl,
      };
    else {
      body = {
        title: title,
        content: content,
        slug: this.getSlug(title, " ", "-"),
        metaTitle: title,
        metaDescription: type,
        summary: title,
        published: true,
        tags: ["1"],
        category: "1"
      };
    }
    return axios
      .post(`${this.DATA_URL}/module/post/${this.API_VERSION}/post/add`, body, this.config)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
        console.log(body);
        console.log(this.config);
      });
  }

  public addComment(comment: string, postId: number, parentId: any){
    let body = {};
    if (parentId)
      body = {
        postId: postId,
        parentId: parentId,
        content: comment
      };
    else {
      body = {
        postId: postId,
        content: comment
      };
    }
    return axios
      .post(`${this.DATA_URL}/module/post/${this.API_VERSION}/comment/add`, body)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public getComments(slug: string) {
    
    return axios
      .get(
        `${this.DATA_URL}/module/post/${this.API_VERSION}/comment/post?slug=${slug}&page=1&per_page=20`
      )
      .then((resp) => {
        //console.log(resp.data);
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public addMedia(formData: FormData) {
    return axios({
      method: 'post',
      url: `${this.DATA_URL}/${this.API_VERSION}/file/upload/lfm`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((resp: any) => {
        //console.log(resp);
        return resp.data.data;
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  public getMedia(userId: number) {
    // console.log(`${this.DATA_URL}/${this.API_VERSION}/file/browse/lfm?workingDir=/${userId}`);
    return axios
      .get(
        `${this.DATA_URL}/${this.API_VERSION}/file/browse/lfm?workingDir=/${userId}`
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public getUsers() {
   return axios
      .get(
        `${this.DATA_URL}/${this.API_VERSION}/users`,
        this.config
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public getFriends() {
    return axios
    .get(
      `${this.DATA_URL}/${this.API_VERSION}/friend`,
      this.config
    )
    .then((resp) => {
      return resp.data;
    })
    .catch((e) => {
      console.log(e);
    });
  }

  public addFriend(userId: number){
    const body = {friendId: userId};
    return axios
      .post(`${this.DATA_URL}/${this.API_VERSION}/friend/add`, body, this.config)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public acceptFriend(fId: number){
    const body = {friendId: fId};
    return axios
      .post(
        `${this.DATA_URL}/${this.API_VERSION}/friend/accept`,
        body, 
        this.config
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public deleteFriend(fId: number){
    const body = {id: fId};
    console.log(body);
    return axios
      .delete(`${this.DATA_URL}/${this.API_VERSION}/friend/delete`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` },
        data: body
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public getActiveUser(id: number) {
    return axios
       .get(
         `${this.DATA_URL}/${this.API_VERSION}/users/read?id=${id}`,
         this.config
       )
       .then((resp) => {
         return resp.data;
       })
       .catch((e) => {
         console.log(e);
       });
  }

  public getUserStats(id: number) {
  //console.log(this.config);
  return axios
      .get(
        `${this.DATA_URL}/${this.API_VERSION}/users/stats?id=${id}`,
        this.config
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public updateUserProfile(body: any) {
  //console.log(this.config);
  /*
  return axios({
      method: 'put',
      url: `${this.DATA_URL}/${this.API_VERSION}/users/edit`,
      data: user,
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  */
  return axios
    .post(
      `${this.DATA_URL}/${this.API_VERSION}/users/editpost`,
      body, 
      this.config
    )
    .then((resp) => {
      return resp.data;
      })
    .catch((e) => {
      console.log(e);
    });
  }

  public deletePost(pId: number){
    const body = {id: pId};
    return axios
      .delete(`${this.DATA_URL}/module/post/${this.API_VERSION}/post/delete`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` },
        data: body
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public deleteComment(cId: number){
    const body = {id: cId};
    return axios
      .delete(`${this.DATA_URL}/module/post/${this.API_VERSION}/comment/delete`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` },
        data: body
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getSlug(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  }
}
