import axios from "axios";

export default function intercept() {
    axios.interceptors.request.use(async function (config) {
        const token: string = await localStorage.getItem("ACCESS_TOKEN");

        if (
            config.url.indexOf('auth/login') < 0 &&
            token && token.length > 0
        ) {
            
            config.headers.Authorization = 'Bearer ' + token;
            return config;
        } else return config;
  });
}
