import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from './user';
import { AuthResponse } from './auth-response';
import axios from 'axios';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly AUTH_SERVER_ADDRESS: string = 'https://globexpace.com/admin';
  private readonly API_VERSION: string = 'v1';
  public isAuthenticated: boolean = false;
  public id: number;
  public name: string;
  public email: string;
  public avatar: string;
  public phone: string;

  constructor(
    private toastCtrl: ToastController,
    private storage: Storage,
  ) { }

  public async register(user: User): Promise<Observable<AuthResponse>> {
    const res: any = await axios.post(
      `${this.AUTH_SERVER_ADDRESS}/${this.API_VERSION}/auth/register`,
      user
    );
    return res;
  }

  public async login(
    email: string,
    password: string,
    remember: boolean
  ): Promise<Observable<AuthResponse>> {
    const body = {
      email: email,
      password: password,
      remember: remember,
    };

    const res: any = await axios.post(
      `${this.AUTH_SERVER_ADDRESS}/${this.API_VERSION}/auth/login`,
      body);

    localStorage.setItem('ACCESS_TOKEN', res.data.data.accessToken);
    localStorage.setItem('EXPIRES_IN', res.data.data.expiresIn);
    localStorage.setItem('USER_ID', res.data.data.user.id);
    localStorage.setItem('USER_AVATAR', res.data.data.user.avatar);
    localStorage.setItem('USER_NAME', res.data.data.user.name);

    this.isAuthenticated = true;
    this.id = res.data.data.user.id;
    this.name = res.data.data.user.name;
    this.email = res.data.data.user.email;
    this.avatar = res.data.data.user.avatar;
    this.phone = res.data.data.user.phone;

    return res;
  }


  public async refreshToken(): Promise<Observable<AuthResponse>> {

      const res: any = await axios.post(
        `${this.AUTH_SERVER_ADDRESS}/${this.API_VERSION}/auth/refresh-token`);

      localStorage.setItem('ACCESS_TOKEN', res.data.data.accessToken);
      localStorage.setItem('EXPIRES_IN', res.data.data.expiresIn);

      this.isAuthenticated = true;
      this.id = res.data.data.user.id;
      this.name = res.data.data.user.name;
      this.email = res.data.data.user.email;
      this.avatar = res.data.data.user.avatar;
      this.phone = res.data.data.user.phone;

      return res;
  }

  public async forgotPassword(
    email: string
  ): Promise<Observable<AuthResponse>> {
    const body = {
      email: email,
    };

    const res: any = await axios.post(
      `${this.AUTH_SERVER_ADDRESS}/${this.API_VERSION}/auth/forgot-password`,
      body,
      {
        headers: {
          "content-type": "text/json",
        },
      }
    );
    return res;
  }

  async logout() {
    try {
      const res: any = await axios.post(
        `${this.AUTH_SERVER_ADDRESS}/${this.API_VERSION}/auth/logout`);

      //localStorage.setItem('ACCESS_TOKEN', res.data.data.accessToken);
      //localStorage.setItem('EXPIRES_IN', res.data.data.expiresIn);

      this.isAuthenticated = false;
      /*
      this.name = res.data.data.user.name;
      this.email = res.data.data.user.email;
      this.avatar = res.data.data.user.avatar;
      this.phone = res.data.data.user.phone;
      */

      return res;
    } catch (err) {
      console.error(err);
    }
  }
}
