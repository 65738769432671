import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuProvider {

  constructor() {}

  public screenSize = new Subject();

  checkScreenSize() {
    const scrWidth = window.innerWidth;
    if (scrWidth < 992) {
      this.screenSize.next('md');
    } else {
      this.screenSize.next('lg');
    }
    return scrWidth;
  }

  static appPages: any = [
    {
      title: "Inicio",
      url: "/app/tabs/schedule",
      tab: "schedule",
      icon: "home",
      topHeader: true
    },
    {
      title: "Amigos",
      url: "/app/tabs/speakers",
      tab: "speakers",
      icon: "people",
      topHeader: true
    },
    {
      title: "Mensajes",
      url: "/app/tabs/chat",
      tab: "chat",
      icon: "chatbubbles",
      topHeader: true
    },
    {
      title: "Mi Perfil",
      url: "/app/tabs/about",
      tab: "about",
      icon: "person",
      topHeader: true
    },
    {
      title: "Notificaciones",
      url: "/app/tabs/alerts",
      tab: "alerts",
      icon: "alert",
      topHeader: false
    },
    {
      title: "Suscripciones",
      url: "/app/tabs/about",
      tab: "about",
      icon: "information-circle",
      topHeader: false
    },
    {
      title: "Noticias",
      url: "/app/tabs/news",
      tab: "news",
      icon: "book",
      topHeader: false
    },
    {
      title: "Comunidades",
      url: "/app/tabs/commmunity",
      tab: "commmunity",
      icon: "people-circle",
      topHeader: false
    },
    {
      title: "Fotos",
      url: "/app/tabs/media",
      tab: "pics",
      icon: "images",
      topHeader: false
    },
    {
      title: "Videos",
      url: "/app/tabs/media",
      tab: "news",
      icon: "videocam",
      topHeader: false
    },
    {
      title: "Guardados",
      url: "/app/tabs/news",
      tab: "news",
      icon: "save",
      topHeader: false
    },
    {
      title: "Favoritos",
      url: "/app/tabs/news",
      tab: "news",
      icon: "star",
      topHeader: false
    },
    {
      title: "Ajustes",
      url: "/app/tabs/news",
      tab: "news",
      icon: "construct",
      topHeader: false
    },
  ];
}
