import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";

import { MenuController, Platform, ToastController } from "@ionic/angular";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Storage } from "@ionic/storage";

import { UserData } from "./providers/user-data";
import { MenuProvider } from "./providers/menu.service";
import { AuthService } from "./auth/auth.service";
import { ApiService } from './providers/api.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  /*encapsulation: ViewEncapsulation.None*/
})
export class AppComponent implements OnInit {
  @ViewChild("screen", { static: false }) screen: any;

  appPages = MenuProvider.appPages;
  loggedIn = false;
  dark = false;
  statPics: number;
  statFriends: number;
  statPosts: number;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private menuProvider: MenuProvider,
    private api: ApiService,
    public auth: AuthService
  ) {
    this.initializeApp();
    this.statusBar.backgroundColorByHexString('#ffffff');

    this.router.events.subscribe((ev: any) => {
      if (this.screen && this.screen.el && ev.url) {
        if (ev.url == "/index") {
          this.screen.el.style.width = "auto";
        } else if (this.platform.width() > 1366) {
          this.screen.el.style.width = "1366px";
        }
      }
    });

    var that = this;
    this.router.events.subscribe((routeData: any) => {
      if (
        routeData &&
        routeData.urlAfterRedirects &&
        routeData.urlAfterRedirects === "/index"
      ) {
        this.screen.el.style.width = "auto";
      } else if (routeData && routeData.urlAfterRedirects) {
        if (this.platform.width() > 1366) this.screen.el.style.width = "1366px";
      }
    });
  }

  async ngOnInit() {
    await this.checkLoginStatus();
    this.listenForLoginEvents();

    await this.updateUserStats(localStorage.getItem('USER_ID'));

    this.swUpdate.available.subscribe(async (res) => {
      const toast = await this.toastCtrl.create({
        message: "Update available!",
        position: "bottom",
        buttons: [
          {
            role: "cancel",
            text: "Reload",
          },
        ],
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }


  async updateUserStats(id: any) {
    try {
      const resp: any = await this.api.getUserStats(id);
      if (resp === undefined) {
        console.log('Problemas al obtener estadisticas');
        return;
      }
      //console.log(resp);
      this.statPics = resp.data.userstats.pics;
      this.statFriends = resp.data.userstats.friends;
      this.statPosts = resp.data.userstats.posts;

    } catch (err) {
        console.log(err);
    }
    //this.storage.set('STATS_PICS',);
  }

  checkLoginStatus() {
    this.auth
      .refreshToken()
      .then((data) => {
        return this.router.navigateByUrl("/app/tabs/schedule");
      })
      .catch((error) => {
        this.openTutorial();
      });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    this.loggedIn = loggedIn;
  }

  listenForLoginEvents() {
    window.addEventListener("user:login", () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener("user:signup", () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener("user:logout", () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.auth.logout().then((data) => {
      this.openTutorial();
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set("ion_did_tutorial", false);
    this.router.navigateByUrl("/index");
    //this.router.navigateByUrl('/login');
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.menuProvider.checkScreenSize();
  }
}
